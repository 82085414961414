import React from "react";
import { usePromiseTracker } from "react-promise-tracker";
import { BeatLoader as Spinner } from "react-spinners";
import LoadingOverlay from "./LoadingOverlay";

export default function LoadingSpinner() {
  const { promiseInProgress } = usePromiseTracker();

  const testingMode = false;

  return (
    <span>
      {promiseInProgress === true || testingMode ? (
        <div className="full-screen">
          <LoadingOverlay />
          <div className="spinner">
            <Spinner
              color="#2e9cca"
              margin-top="50%"
              height="190"
              width="200"
               radius="100"
               margin="25px"
              top="50%"
              left="50%"
            />
          </div>
        </div>
      ) : null}
    </span>
  );
}
