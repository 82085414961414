import logo from "./logo.svg";
import "./App.css";
import SavingsCalculator from "./pages/SavingsCalculator";
import { useRef } from "react";
import LoadingSpinner from "./controls/LoadingSpinner";
import { useState } from "react";
import ScaleControl from "./pages/ScaleControl";

function App() {
  const [scaleVal, setScaleVal] = useState(1);

  return (
    // <div className={["App", 'scale' + scaleVal.toString() ].join(" ")}>
    //   <ScaleControl
    //   scaleVal={scaleVal}
    //   setScaleVal={setScaleVal}
    //   />

    <div className={["App"].join(" ")}>
      <LoadingSpinner />
      <div className={["workspace"].join(" ")}>
        <SavingsCalculator scaleVal={scaleVal} />
      </div>
    </div>
  );
}

export default App;
