
import React from "react";
import { Image } from "react-bootstrap";
import "./splashscreen.css";
//import Logo from "../pascalgoldframe.svg";
import Logo from "../resources/PasCALGradient.svg";


export default function SplashScreen({
  setShowSplashScreen,
  showSplashScreen,
}) {
  function handleClick() {
    if (setShowSplashScreen != null) {
      setShowSplashScreen(false);
    }
  }

  return (
    <>
      <div
        className={[
          "splash-screen-page",
          showSplashScreen === true ? "" : "clear",
        ].join(" ")}
        onClick={handleClick}
      >
        <div className={["text-block", "disp-block"].join(" ")}>
          Meet Pascal
          <div className={["touch-label"]}>(Touch Screen to Start)</div>
        </div>

        <div>
          <Image src={Logo} className="pascal-logo" />
        </div>

        <div className={["text-tri-block"].join(" ")}>
          <div className={["text-block"].join(" ")}>KensieMae CSO</div>
          <div className={["text-block", "text-sm"].join(" ")}>
            (Chief Savings Officer)
          </div>
        </div>
      </div>
    </>
  );
}
