import React, { useEffect, useRef, useState } from "react";
import "./slidercontrol.css";
import { useSwipeable } from "react-swipeable";
import useWindowDimensions from "../hooks/useWindowDimensions";

var baseLeft = 208;
var itemWid = 70;

export default function SliderControl({
  setCurrNum,
  currNum,
  minVal,
  maxVal,
  step,
  abbrev = "",
  manualValue = null,
  setManualValue,
  expanded,
}) {
  const [left, setLeft] = useState(baseLeft);
  const [totalDelta, setTotalDelta] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const barRef = useRef();
  const itemRef = useRef();
  const top = 0;
  const { height, width } = useWindowDimensions();

  var numbers = [];

  useEffect(() => {

    // Get Interval value
    if (manualValue == null || manualValue < minVal) return;
    var tmpVal = parseInt(manualValue);
    var updown = tmpVal % step >= step / 2 ? true : false;
    var tmp = tmpVal - (tmpVal % step);
    var idx = numbers.indexOf(tmp);

    if (idx == -1) {
      idx = numbers.length;
    } else {
      idx = idx + (updown ? 1 : 0);
    }

    if (idx == null || idx < 0) {
      // alert("Invalid Entry");
    } else {
      setTotalDelta(idx * itemWid * -1);
      setManualValue(null);
    }
  }, [manualValue]);

  useEffect(() => {
    const { offsetWidth } = barRef.current;
    baseLeft = Math.round(offsetWidth) / 2 - itemWid / 2 + 2;
  }, [barRef]);

  useEffect(() => {
    const { offsetWidth } = itemRef.current;
    itemWid = Math.round(offsetWidth);
  }, [itemRef]);

  useEffect(() => {
    calculateLeft();
  }, [totalDelta]);

  useEffect(() => {
    if (itemRef != null && barRef != null) {
    var itemRefOffset = getItemOffset();
    itemWid = Math.round(itemRefOffset);

    var barOffsetWidth = getBarOffset();
    baseLeft = Math.round(barOffsetWidth) / 2 - itemWid / 2 + 2;
     calculateLeft();
  }
  }, [height, width])

  function getItemOffset() {
    const { offsetWidth } = itemRef.current;
    return offsetWidth;
  }
  function getBarOffset() {
    const { offsetWidth } = barRef.current;
    return offsetWidth;
  }

  //   var numbers = [];
  for (var i = minVal; i <= maxVal; i += step) {
    numbers.push(i);
  }

  var config = {
    delta: 8, // min distance(px) before a swipe starts. *See Notes*
    preventScrollOnSwipe: true, // prevents scroll during swipe (*See Details*)
    trackTouch: true, // track touch input
    trackMouse: true, // track mouse input
    rotationAngle: 0, // set a rotation angle
    swipeDuration: Infinity, // allowable duration of a swipe (ms). *See Notes*
    touchEventOptions: { passive: true }, // options for touch listeners (*See Details*)
  };

  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      var multiplier = 4;
      setTotalDelta(
        totalDelta +
          eventData.deltaX * Math.max(eventData.velocity * multiplier, 1)
      );
    },
    ...config,
  });

  const calculateLeft = () => {
    var newLeft = Math.round(baseLeft + totalDelta);
    var maxRight = baseLeft - itemWid * numbers.length + itemWid;

    newLeft = Math.min(baseLeft, Math.max(newLeft, maxRight));

    var i = Math.round((newLeft * -1) / itemWid);
    i = i + Math.round(baseLeft / itemWid);
    var currentNumber = numbers[i];

    if (currentNumber != null) {
      setCurrNum(numbers[i]);
      setActiveIndex(i);
    }

    newLeft = baseLeft + i * itemWid * -1;
    setLeft(newLeft);
  };

  function setItem(e, num) {
    var idx = numbers.indexOf(num);

    const { offsetWidth } = itemRef?.current;
    if (offsetWidth != null) {
      if (offsetWidth !== itemWid) {
        itemWid = offsetWidth;
      }
      if (!(idx == null || idx < 0)) {
        setTotalDelta(idx * (itemWid * -1));
      }
      e.stopPropagation();
    }
  }

  return (
    <>
      <div className={["slide-container"].join(" ")} ref={barRef}>
        <div className={["slide-container-overlay"].join(" ")} />

        <div className={["slide-container-bar"].join(" ")}></div>

        <span className="splitter"></span>
        <span className="splitterR"></span>
        <div className={["slide-bar-container"].join(" ")} {...handlers}>
          <div
            className={["slide-middle-bar"].join(" ")}
            style={{ left, top, position: "relative" }}
          >
            {numbers.map((num, i) => (
              <span
                key={i}
                className={[
                  "slide-number-item",
                  "noselect",
                  activeIndex === i ? "active" : "",
                ].join(" ")}
                ref={itemRef}
                onClick={(e) => setItem(e, num)}
              >
                {num}
                {num > 0 ? abbrev : ""}
              </span>
            ))}
          </div>
        </div>

        <div className={["slide-container-bar"].join(" ")}></div>
      </div>
    </>
  );
}
