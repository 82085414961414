export const fetchService = {
  get,
  post,
  put,
  delete: _delete,
  getBaseUrl,
};

const baseEntityAPIUrl = process.env.REACT_APP_KMPASCAL_APIURL;
const apiKey = process.env.REACT_APP_KMPASCAL_APIKEY;

function getBaseUrl() {
  return baseEntityAPIUrl;
}

function get(apiroute) {
  let url = baseEntityAPIUrl + apiroute;

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Access-Control-Allow-Credentials", "true");

  const requestOptions = {
    method: "GET",
    headers: authHeader(url),
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .catch((error) => {
      // history.push("login");
    });
}

function post(apiroute, body) {
  let url = baseEntityAPIUrl + apiroute;
  const requestOptions = {
    credentials: "same-origin",
    method: "POST",
    headers: { "Content-Type": "application/json", ...authHeader(url) },
    body: JSON.stringify(body),
  };
  return fetch(url, requestOptions).then(handleResponse);
}

function put(apiroute, body) {
  let url = baseEntityAPIUrl + apiroute;

  const requestOptions = {
    method: "PUT",
    headers: { "Content-Type": "application/json", ...authHeader(url) },
    body: JSON.stringify(body),
  };
  return fetch(url, requestOptions).then(handleResponse);
}

// prefixed with underscored because delete is a reserved word in javascript
function _delete(apiroute) {
  let url = baseEntityAPIUrl + apiroute;

  const requestOptions = {
    method: "DELETE",
    headers: authHeader(url),
  };
  return fetch(url, requestOptions).then(handleResponse);
}

// helper functions

function authHeader(url) {
  return { "x-km-api-key": apiKey };
}

function handleResponse(response) {
  return response.text().then((text) => {
    let data;
    if (IsValidJSONString(text)) {
      data = text && JSON.parse(text);
    } else {
      data = text;
    }

    if (!response.ok) {
      if ([401].includes(response.status)) {
        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
      }
      if ([403].includes(response.status)) {
        return Promise.reject(
          "Unauthorized access attempted, please contact system administrator."
        );
      }

      // const error = (data && data.message) || response.statusText;
      const error = data || response.status;
      return Promise.reject(error);
    }

    return data;
  });

  function IsValidJSONString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
}
