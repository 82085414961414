import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import "./dropdowncontrol.css";
import { useEffect } from "react";

export default function DropDownControl({
  items,
  itemValueChanged,
  placeHolder,
  value,
  reset,
  setReset
}) {
  const [expanded, setExpanded] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    if (reset){
        if (expanded){
            setExpanded(false);
        }
        setReset(false);
    }
  }, [reset])

  function selectedItemHandler(itemName) {
    setSelectedItem(itemName);
    itemValueChanged("accountManager", itemName);
  }

  return (
    <>
      <div
        className={[
          "form-control",
          "dropdown-slider",
          expanded ? "expanded" : "",
        ].join(" ")}
        onClick={() => setExpanded(!expanded)}
      >
        <div className="dropdown-row-grid">
          <div>
            {value == null ? (
              <span className="dropdown-placeholder">{placeHolder}</span>
            ) : (
              value
            )}
          </div>

          <div className={[expanded === true ? "expanded" : ""].join(" ")}>
            <FontAwesomeIcon
              onClick={() => setExpanded(false)}
              icon={expanded ? faChevronUp : faChevronDown}
            ></FontAwesomeIcon>
          </div>
        </div>

        <div
          className={["dropdown-expand-panel", expanded ? "expanded" : ""].join(
            " "
          )}
        >
          <div className={["dropdown-expand-panel-container"].join(" ")}>
            {items.map((am, i) => (
              <div
                className={["dropdown-control-item"].join(" ")}
                key={i}
                onClick={() => selectedItemHandler(am.display)}
              >
                {am.display}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
