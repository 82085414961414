import React from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import "./bettercheckbox.css";

export default function BetterCheckbox({
  keyId,
  checked,
  onChange,
  label,
  showBold = false,
  tooltipText = "",
  disabled = false,
  nolabel = false,
  readOnly = false,
}) {
//   const renderTooltip = (props) => (
//     <Tooltip id="button-tooltip" {...props}>
//       {tooltipText}
//     </Tooltip>
//   );

  let checkboxScript = (
    <label
      key={"l" + keyId?.toString()}
      className={
        "better_custom_checkbox" +
        (showBold ? " bold-text" : "") +
        (readOnly ? " read-only-cb" : "")
      }
    >
      <input
        type="checkbox"
        key={keyId?.toString()}
        checked={checked}
        onChange={onChange}
        disabled={disabled || readOnly}
      />
      <span key={'s' + keyId?.toString()} className={"noselect"}>{label}</span>
    </label>
  );

  return (
    <div
      key={"c" + keyId?.toString()}
      className={
        nolabel ? "better_cb_container_nolabel" : "better_cb_container"
      }
    >
      {checkboxScript}
    </div>

    //     {

    //         tooltipText === ''
    //             ?
    //             <>
    //                 { checkboxScript}
    //             </>
    //             :
    //             <OverlayTrigger
    //                 // placement="right"
    //                 delay={{ show: 250, hide: 400 }}
    //                 overlay={renderTooltip}
    //             >
    //                 {checkboxScript}
    //             </OverlayTrigger>
    //     }
    // </div>
  );
}
