import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import MultiCheckboxControl from "./MultiCheckboxControl";
import ControlOverlay from "./ControlOverlay";

export default function MultiChecboxSelector({
  defaultVal,
  labelText,
  cbOptions,
  selectedOptions,
  setSelectedOptions,
  index,
  expanded,
  setExpanded,
  setControlValue,
  resetVal,
}) {
  useEffect(() => {
    setControlValue(index, selectedOptions.length);
  }, [selectedOptions]);

  useEffect(() => {
    if (resetVal) {
      setSelectedOptions([]);
    }
  }, [resetVal]);

  function expandClick() {
    setExpanded(index, !expanded);
    return;

    if (!expanded) {
      setExpanded(index, true);
      return;
    }
  }

  function getLabel() {
    if (selectedOptions != null && selectedOptions.length > 0) {
      return (
        <>
          <span className="bold-und">{selectedOptions.length.toString()}</span>
          {labelText.replace("[tag]", " ")}
        </>
      );
    } else {
      return <>{labelText.replace("[tag]", defaultVal)}</>;
    }
  }

  function getCountLabel() {
    var solutions = selectedOptions.filter((x) => x.selected === true);
    if (solutions != null && solutions.length > 0) {
      return solutions.length.toString();
    }
  }

  return (
    <>
      <ControlOverlay visible={expanded} onClick={expandClick} />

      <div
        className={[
          "form-control",
          "cb-slider",
          expanded ? "expanded" : "",
        ].join(" ")}
        
      >
        <div className="slider-row-grid" onClick={expandClick}>
          <div className={["ctl-num-lbl"].join(" ")}>
            <span className={["marg-auto"].join(" ")}>{getCountLabel()}</span>
          </div>

          <div className={["slider-row-text"].join(" ")}>
            {labelText}

            <span className="open-close-icon">
              <div>
                <FontAwesomeIcon
                  onClick={() => setExpanded(index, !expanded)}
                  icon={expanded ? faChevronUp : faChevronDown}
                ></FontAwesomeIcon>
              </div>
            </span>
          </div>
        </div>

        <div
          className={[
            "expand-panel",
            "expand-panel-cb",
            expanded ? "expanded" : "",
          ].join(" ")}
        >
          <div className={["cb-expand-panel-container"].join(" ")}>
            <div className="cb-grid-container">
              <MultiCheckboxControl
                key={22}
                cbOptions={cbOptions}
                setSelectedOptions={setSelectedOptions}
                selectedOptions={selectedOptions}
              />
            </div>

            <div
              className={[
                "fa-excoll-icon",
                expanded === true ? "expanded" : "",
              ].join(" ")}
            >
              <FontAwesomeIcon
                onClick={() => setExpanded(index, false)}
                icon={faChevronUp}
              ></FontAwesomeIcon>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
