import React, { useState } from "react";
import { Modal, Button, Form} from "react-bootstrap";
import CurrencyTextInput from "./CurrencyTextInput";
export default function PopupNumericEntry({
  showModal,
  setShowModal,
  title,
  callBackResultFunction,
}) {
  const [value, valueChange] = useState(null);

  function saveClicked() {
    callBackResultFunction(value);
    setShowModal(false);
  }

  function keyPress(e) {
    if (e.key === "Enter"){
      saveClicked();
    }
  }
 
  return (
    <>
      {showModal ? (
        <Modal
          show={setShowModal}
          onHide={() => setShowModal(false)}
          backdrop={"static"}
          size={'lg'}
        >
          <Modal.Header closeButton>
            <Modal.Title>{'Enter Manual Value'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div id="inputForm">
              <CurrencyTextInput
                label={"Enter Value"}
                value={value}
                valueChange={valueChange}
                onKeyPress={keyPress}
              ></CurrencyTextInput>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className={["btn-primary", "mr-3"].join(" ")}
              onClick={saveClicked}
            >
              Continue
            </Button>
            <Button variant="link" onClick={() => setShowModal(false)}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      ) : (
        <></>
      )}
    </>
  );
}
