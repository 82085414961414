import React from "react";
import { Form, Image, Button } from "react-bootstrap";
import BetterCheckbox from "../controls/BetterCheckbox/BetterCheckbox";
import { fetchService } from "../utils/fetch.service";
import { trackPromise } from "react-promise-tracker";
import { useState } from "react";
import DropDownControl from "../controls/DropDownControl";
import PascalHeader from "./PascalHeader";
import TextInput from "../controls/TextInput";

export default function InputForm({
  showModal,
  setShowModal,
  resetApp,
  acronymName,
  Logo,
  getImage,
  image,
  loanCount,
  monthlyClicks,
  monthlyDollars,
  tools,
}) {
  const [inputValues, setInputValues] = useState({});
  const [validated, setValidated] = useState(null);
  const [reset, setReset] = useState(false);

  const accountManagers = [
    {
      display: "Aaron",
      value: "aaronfloyd",
    },
    {
      display: "Bailey",
      value: "baileycoyle",
    },
    {
      display: "Jeff",
      value: "jeffgorodokin",
    },
    {
      display: "Stephen",
      value: "srescigno",
    },
    {
      display: "Tami",
      value: "tamivontour",
    },
    {
      display: "I'm not sure",
      value: "notsure",
    },
    {
      display: "I don't have one yet",
      value: "none",
    },
  ];

  function setValue(name, val) {
    var tmp = inputValues;
    tmp[name] = val;
    setInputValues(JSON.parse(JSON.stringify(tmp)));
  }

  function getValue(name) {
    var val = inputValues[name];
    if (val == null) {
      return "";
    }

    return inputValues[name];
  }

  function backButton() {
    setShowModal(false);
    setReset(true);
    setInputValues({});
    setValidated(false);
  }

  function handleSave() {
    if (!validateInput()) {
      return;
    }
    var tmp = inputValues;
    tmp.b64ScreenShot = image;

    tmp.loanCount = loanCount;
    tmp.monthlyDollars = monthlyDollars();
    tmp.monthlyClicks = monthlyClicks();
    tmp.tools = tools();

    setReset(true);
    

    trackPromise(fetchService.post("/customerInfo", tmp))
      .then((json) => {
        setShowModal(false);
        resetApp();
        setInputValues({});
        setValidated(false);
      })
      .catch((error) => {
        // alert(error);
        return;
      });
  }

  const validateInput = () => {
    const form = document.querySelectorAll("#submitInfo")[0];
    if (form.checkValidity() === false) {
      setValidated(true);
      return false;
    }

    setValidated(false);
    return true;
  };

  function onSumbitForm() {
    return false;
  }

  return (
    <div
      className={[
        "input-form-page",
        "page-container",
        showModal ? "" : "clear",
      ].join(" ")}
    >
      <div className={["page-header-row", "mw-row"].join(" ")}>
        <div>
          <Image src={Logo} className="kmt-logo" />
        </div>
      </div>
      <div className="ninety-per">
        <PascalHeader />
      </div>
      <div className="form-container">
        <Form
          autoComplete={"none"}
          id="submitInfo"
          noValidate
          validated={validated}
          onSubmit={onSumbitForm}
        >
          <div className="modal-form">
            <div>
              <Form.Label className="frm-label">Company Name</Form.Label>
            </div>
            <div>
              <Form.Control
                type="text"
                placeholder=""
                onChange={(e) => setValue("companyName", e.target.value)}
                required
                value={getValue("companyName")}
                name={"new" + Math.random()}
                autoComplete={"off"}
                
              />
            </div>

            <div>
              <Form.Label className="frm-label">Your Name</Form.Label>
            </div>
            <div>
              <TextInput
                name={"customerName"}
                type="text"
                placeholder=""
                onChange={(e) => setValue("customerName", e.target.value)}
                isEmail={false}
                value={getValue("customerName")}
                required={true}
              />
            </div>

            <div>
              <Form.Label className="frm-label">Your Email</Form.Label>
            </div>

            <TextInput
              name={"email"}
              type="email"
              placeholder=""
              onChange={(e) => setValue("email", e.target.value)}
              isEmail={true}
              value={getValue("email")}
              required={true}
            />

            <div>
              <Form.Label className="frm-label">Your Position</Form.Label>
            </div>
            <div>
              <Form.Control
                type="text"
                placeholder=""
                onChange={(e) => setValue("position", e.target.value)}
                value={getValue("position")}
              />
            </div>

            <div>
              <Form.Label className="frm-label">Account Manager</Form.Label>
            </div>

            <DropDownControl
              items={accountManagers}
              placeHolder={"Select Account Manager"}
              itemValueChanged={setValue}
              value={getValue("accountManager")}
              reset={reset}
              setReset={setReset}
            />

            <div>
              <Form.Label className="frm-label">Request a Demo</Form.Label>
            </div>
            <div>
              <BetterCheckbox
                label={"Yes, Please"}
                onChange={(e) => setValue("requestADemo", e.target.checked)}
                checked={getValue("requestADemo")}
              />
            </div>
            <div>
              <Form.Label className="frm-label">Request Agreement</Form.Label>
            </div>
            <div>
              <BetterCheckbox
                label={"Heck Yes, I want these yesterday"}
                onChange={(e) => setValue("requestAgreement", e.target.checked)}
                checked={getValue("requestAgreement")}
              />
            </div>
          </div>
        </Form>

        <div className={["page-footer", "form-footer", "mw-row"].join(" ")}>
          <Button
            className={["kmt-savings-button"].join(" ")}
            variant="secondary"
            onClick={backButton}
          >
            Back
          </Button>
          <div />
          <div />
          <Button
            className={["kmt-savings-button", "save"].join(" ")}
            variant="success"
            onClick={handleSave}
          >
            Submit and Reset
          </Button>
        </div>
      </div>
    </div>
  );
}
