
import React from "react";
import { Image } from "react-bootstrap";
// import Logo from "../resources/pascal.svg";
import Logo from "../resources/PasCALNoBorderGradient.svg";
import "./pascalheader.css";

export default function PascalHeader() {
  return (
    <div className={["pascal-hdr-cont"].join(" ")}>
      <div className={["pascal-hdr-inner"].join(" ")}>
        <div className="pascal-anagram">
          <div className={["pascal-hdr-textblock"].join(" ")}>
            <span className={["accent"]}>P</span>
            rocess
          </div>
          <div className={["pascal-hdr-textblock"].join(" ")}>
            <span className={["accent"]}>A</span>
            utomation
          </div>
          <div className={["pascal-hdr-textblock"].join(" ")}>
            <span className={["accent"]}>S</span>
            avings
          </div>
          <div className={["pascal-hdr-textblock"].join(" ")}>
            <span className={["accent"]}>CAL</span>
            culator
          </div>
        </div>

        <div className={["pascal-hdr-logo-cont"]}>
          <Image src={Logo} className="pascal-hdr-logo" />

          <div className={["text-tri-block"].join(" ")}>
            <div className={["text-block"].join(" ")}>Pascal - CSO</div>
            <div className={["text-block", "text-sm"].join(" ")}>
              (Chief Savings Officer)
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
