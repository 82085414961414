import React from "react";
import Form from "react-bootstrap/Form";

const TextInput = ({
  name,
  onChange,
  placeholder,
  value,
  id,
  key,
  required,
  validatefailtext,
  disabled,
  readOnly,
  onFocus,
  inputref,
  isEmail = false,
  pattern = "",
}) => {
  function onFocusHandler(event) {
    if (onFocus != null) {
      onFocus(event);
    } else {
      // event.target.setAttribute('autocomplete', 'none');
      event.target.setAttribute(
        "autocomplete",
        "none" + randomNumber().toString()
      );
    }
  }

  function randomNumber() {
    return Math.floor(Math.random() * 10000 + 1).toString();
  }

  return (
    <div className="form-group">
      <input
        type="password"
        autoComplete={"new-password"}
        style={{
          width: 0,
          height: 0,
          visibility: "hidden",
          position: "absolute",
          left: 0,
          top: 0,
        }}
      />
      <div className="field">
        <input
          autoComplete={randomNumber() + "new-input-" + name}
          id={id}
          key={key}
          type={isEmail ? "email" : "text"}
          // aria-label={label}
          name={randomNumber() + name}
          className="form-control"
          placeholder={placeholder}
          value={value ?? ""}
          onChange={onChange}
          required={required}
          disabled={disabled}
          readOnly={readOnly}
          onFocus={onFocusHandler}
          ref={inputref}
          //pattern={pattern}
        />
        <Form.Control.Feedback type="invalid">
          {validatefailtext}
        </Form.Control.Feedback>
      </div>
    </div>
  );
};

export default TextInput;
