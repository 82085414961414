import React from "react";
import './controloverlay.css';

export default function ControlOverlay({visible, onClick}) {


    return (
        <div 
        className={["control-overlay", visible ? "" : "hidden"].join(" ")}
        onClick={onClick}
        />

    )

}