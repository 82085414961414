import React, { useEffect, useRef, useState } from "react";
import BetterCheckbox from "../controls/BetterCheckbox/BetterCheckbox";
import { products } from "../utils/calcs";

export default function MultiCheckboxControl({
  cbOptions,
  selectedOptions,
  setSelectedOptions,
}) {
  function itemChanged(e, item) {
    e.stopPropagation()
    var tmpArr = [...selectedOptions];
    var isChecked = e.target.checked;
    var obj = tmpArr.find((x) => x.key === item.Id);

    if (obj != null) {
      if (isChecked) {
        obj.selected = isChecked;
      } else {
        var idx = tmpArr.indexOf(obj);
        if (idx > -1) tmpArr.splice(idx, 1);
      }
    } else {
      if (isChecked) {
        tmpArr.push({
          key: item.Id,
          selected: isChecked,
          checked: e.target.checked,
        });
      }
    }
    setSelectedOptions(tmpArr);
  }

  function getItemChecked(item) {
    var obj = selectedOptions.find((x) => x.key === item.Id);
    if (obj != null) {
      return obj.selected;
    }
    return false;
  }

  return (
    <>
      <div key={'cont99'} className={["cb-container"].join(" ")}>
        {cbOptions
          ?.sort(function (a, b) {
            let x = a.Name.toLowerCase();
            let y = b.Name.toLowerCase();
            if (x < y) {
              return -1;
            }
            if (x > y) {
              return 1;
            }
            return 0;
          })
          .map((item, i) => (
              <BetterCheckbox
                keyId={i}
                key={'cb' + i.toString()}
                onChange={(e) => itemChanged(e, item)}
                label={item.Name}
                checked={getItemChecked(item)}
              />
          ))}
      </div>
    </>
  );
}
