import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faKeyboard,
} from "@fortawesome/free-solid-svg-icons";
import SliderControl from "./SliderControl";
import PopupNumericEntry from "../modals/PopupNumericEntry";
import ControlOverlay from "./ControlOverlay";

export default function NumericSliderSelector({
  defaultVal,
  labelText,
  index,
  expanded,
  setExpanded,
  minVal,
  maxVal,
  step,
  abbrev,
  isMoney = false,
  setControlValue,
  resetVal,
  defaultStartVal,
}) {
  const [currNum, setCurrNum] = useState(10);
  const [showInputForm, setShowInputForm] = useState(false);
  const [manualValue, setManualValue] = useState(defaultStartVal);

  useEffect(() => {
    updateControlVal(currNum);
  }, [currNum]);

  useEffect(() => {
    if (resetVal) {
      setManualValue(defaultStartVal);
    }
  }, [resetVal]);

  function expandClick() {
    setExpanded(index, !expanded);
    return;
    if (!expanded) {
      setExpanded(index, true);
      return;
    }
  }

  function getCountLabel() {
    if (currNum != null && currNum > 0) {
      return currNum + (abbrev != null ? abbrev : "");
    }
  }

  function modalInputCallback(input) {
    setManualValue(input);
    setShowInputForm(false);
  }

  function updateControlVal(val) {
    setControlValue(index, val);
  }

  return (
    <>
      {showInputForm ? (
        <PopupNumericEntry
          showModal={showInputForm}
          setShowModal={setShowInputForm}
          callBackResultFunction={modalInputCallback}
        />
      ) : (
        <></>
      )}

      <ControlOverlay visible={expanded} onClick={expandClick} />

      <div
        className={[
          "form-control",
          "numeric-slider",
          expanded ? "expanded" : "",
        ].join(" ")}
      >
        <div className="slider-row-grid" onClick={expandClick}>
          <div className={["ctl-num-lbl"].join(" ")}>
            <span className={["marg-auto"].join(" ")}>{getCountLabel()}</span>
          </div>

          <div className={["slider-row-text"].join(" ")}>
            {labelText}
            <span className="open-close-icon">
              <div>
                <FontAwesomeIcon
                  onClick={() => setExpanded(index, !expanded)}
                  icon={expanded ? faChevronUp : faChevronDown}
                ></FontAwesomeIcon>
              </div>
            </span>
          </div>
        </div>

        <div className={["expand-panel", expanded ? "expanded" : ""].join(" ")}>
          <div className={["expand-panel-container"].join(" ")}>
            <div>
              <SliderControl
                setCurrNum={setCurrNum}
                minVal={minVal}
                maxVal={maxVal}
                step={step}
                abbrev={abbrev}
                manualValue={manualValue}
                setManualValue={setManualValue}
                expanded={expanded}
              />
            </div>
            <div
              className={[
                "fa-excoll-icon",
                expanded === true ? "expanded" : "",
              ].join(" ")}
            >
              <FontAwesomeIcon
                onClick={() => setExpanded(index, false)}
                icon={faChevronUp}
              ></FontAwesomeIcon>

              <FontAwesomeIcon
                className="far-right"
                onClick={() => setShowInputForm(true)}
                icon={faKeyboard}
              ></FontAwesomeIcon>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
