export const calcA = (loansPerMonth) => {
  return loansPerMonth;
};
export const calcB = (loansPerMonth) => {
  return loansPerMonth / 0.19;
};
export const calcC = (loansPerMonth) => {
  return loansPerMonth / 0.36;
};
export const calcD = (loansPerMonth) => {
  return loansPerMonth / 0.72;
};
export const calcE = (averageSalary) => {
  return averageSalary * 1000;
};

function getProduct(productId) {
  var prdct = products.find((x) => x.Id === productId);
  return prdct;
}

export const Ccalc1 = (productId, loansPerMonth, annualSalary) => {
  var prdct = getProduct(productId);
  return (prdct?.Clicks * prdct?.Emult) * calcA(loansPerMonth);
};

export const Ccalc2 = (productId, loansPerMonth, annualSalary) => {
  var prdct = getProduct(productId);
  return (prdct?.Clicks * prdct?.Emult) * calcB(loansPerMonth);
};

export const Ccalc3 = (productId, loansPerMonth, annualSalary) => {
  var prdct = getProduct(productId);
  return (prdct?.Clicks * prdct?.Emult) * calcC(loansPerMonth);
};

export const Ccalc4 = (productId, loansPerMonth, annualSalary) => {
  var prdct = getProduct(productId);
  return (prdct?.Clicks * prdct?.Emult) * calcD(loansPerMonth);
};

export const Mcalc1 = (productId, loansPerMonth, annualSalary) => {
  var prdct = getProduct(productId);
  var calc1 = ((((prdct.Seconds * prdct.Emult) * calcA(loansPerMonth)) / 60) / 60);
  var ftecost = (calcE(annualSalary) / 52) / 40;
  return calc1 * ftecost;
};
export const Mcalc2 = (productId, loansPerMonth, annualSalary) => {

  var prdct = getProduct(productId);
  var calc1 = ((((prdct.Seconds * prdct.Emult) * calcB(loansPerMonth)) / 60) / 60);
  var ftecost = (calcE(annualSalary) / 52) / 40;
  return calc1 * ftecost;
};
export const Mcalc3 = (productId, loansPerMonth, annualSalary) => {
  var prdct = getProduct(productId);
  var calc1 = ((((prdct.Seconds * prdct.Emult) * calcC(loansPerMonth)) / 60) / 60);
  var ftecost = (calcE(annualSalary) / 52) / 40;
  return calc1 * ftecost;
};
export const Mcalc4 = (productId, loansPerMonth, annualSalary) => {
  var prdct = getProduct(productId);
  var calc1 = ((((prdct.Seconds * prdct.Emult) * calcD(loansPerMonth)) / 60) / 60);
  var ftecost = (calcE(annualSalary) / 52) / 40;
  return calc1 * ftecost;
};


export const Hcalc1 = (productId, loansPerMonth, annualSalary) => {
  var prdct = getProduct(productId);
  var calc1 = ((((prdct.Seconds * prdct.Emult) * calcA(loansPerMonth)) / 60) / 60);
  return calc1;
};
export const Hcalc2 = (productId, loansPerMonth, annualSalary) => {
  var prdct = getProduct(productId);
  var calc1 = ((((prdct.Seconds * prdct.Emult) * calcB(loansPerMonth)) / 60) / 60);
  return calc1;
};
export const Hcalc3 = (productId, loansPerMonth, annualSalary) => {
  var prdct = getProduct(productId);
  var calc1 = ((((prdct.Seconds * prdct.Emult) * calcC(loansPerMonth)) / 60) / 60);
  return calc1;
};
export const Hcalc4 = (productId, loansPerMonth, annualSalary) => {
  var prdct = getProduct(productId);
  var calc1 = ((((prdct.Seconds * prdct.Emult) * calcD(loansPerMonth)) / 60) / 60);
  return calc1;
};


export const Acalc = (calc, productId, loansPerMonth, annualSalary) => {
  return calc(productId, loansPerMonth, annualSalary) * 12;
};


export const products = [
    { Id: 1, Name: 'Piper', Calcs: [Ccalc2, Mcalc2, Hcalc2], Emult: 3, Clicks: 16, Seconds: 89.61, val: false},
    { Id: 2, Name: 'CRA Express Check', Calcs: [Ccalc2, Mcalc2, Hcalc2], Emult: 1, Clicks: 20, Seconds: 48, val: false},
    { Id: 3, Name: '3.2 Back Up', Calcs: [Ccalc3, Mcalc3, Hcalc3], Emult: 3, Clicks: 11, Seconds: 68, val: false},
    { Id: 4, Name: 'Address Verification', Calcs: [Ccalc2, Mcalc2, Hcalc2], Emult: 2, Clicks: 31, Seconds: 84, val: false},
    { Id: 5, Name: 'Advanced Email Utility', Calcs: [Ccalc2, Mcalc2, Hcalc2], Emult: 5, Clicks: 7, Seconds: 19, val: false},
    { Id: 6, Name: 'Auto Printer', Calcs: [Ccalc2, Mcalc2, Hcalc2], Emult: 5, Clicks: 19, Seconds: 201, val: false},
    { Id: 7, Name: 'Auto Role Assignment', Calcs: [Ccalc2, Mcalc2, Hcalc2], Emult: 2, Clicks: 10, Seconds: 13, val: false},
    { Id: 8, Name: 'Batch Update Utility', Calcs: [Ccalc2, Mcalc2, Hcalc2], Emult: 5, Clicks: 8, Seconds: 32, val: false},
    { Id: 9, Name: 'Batch Update Utility Pro', Calcs: [Ccalc2, Mcalc2, Hcalc2], Emult: 5, Clicks: 14, Seconds: 96, val: false},
    { Id: 10, Name: 'CHUMS ID Auto Assign', Calcs: [Ccalc3, Mcalc3, Hcalc3], Emult: 1, Clicks: 2, Seconds: 20, val: false},
    { Id: 11, Name: 'Condition Manager', Calcs: [Ccalc4, Mcalc4, Hcalc4], Emult: 20, Clicks: 8, Seconds: 45, val: false},
    { Id: 12, Name: 'Document Access Control', Calcs: [Ccalc3, Mcalc3, Hcalc3], Emult: 5, Clicks: 5, Seconds: 20, val: false},
    { Id: 13, Name: 'Door Bell', Calcs: [Ccalc2, Mcalc2, Hcalc2], Emult: 2, Clicks: 10, Seconds: 215, val: false},
    { Id: 14, Name: 'eFolder Doc Viewer', Calcs: [Ccalc3, Mcalc3, Hcalc3], Emult: 5, Clicks: 2, Seconds: 10, val: false},
    { Id: 15, Name: 'Encompass File Exporter', Calcs: [Ccalc3, Mcalc3, Hcalc3], Emult: 2, Clicks: 24, Seconds: 225, val: false},
    { Id: 16, Name: 'eOrganizer', Calcs: [Ccalc3, Mcalc3, Hcalc3], Emult: 2, Clicks: 19, Seconds: 49, val: false},
    { Id: 17, Name: 'Escrow Auto Disbursement', Calcs: [Ccalc1, Mcalc1, Hcalc1], Emult: 1, Clicks: 15, Seconds: 54, val: false},
    { Id: 18, Name: 'FEMA Auto Disaster Check', Calcs: [Ccalc3, Mcalc3, Hcalc3], Emult: 2, Clicks: 22, Seconds: 88, val: false},
    { Id: 19, Name: 'Get MI Popup', Calcs: [Ccalc2, Mcalc2, Hcalc2], Emult: 2, Clicks: 4, Seconds: 16, val: false},
    { Id: 20, Name: 'Go To Form', Calcs: [Ccalc2, Mcalc2, Hcalc2], Emult: 10, Clicks: 3, Seconds: 14, val: false},
    { Id: 21, Name: 'Home Counselor', Calcs: [Ccalc2, Mcalc2, Hcalc2], Emult: 1, Clicks: 5, Seconds: 65, val: false},
    { Id: 22, Name: 'Input Form Control', Calcs: [Ccalc2, Mcalc2, Hcalc2], Emult: 4, Clicks: 3, Seconds: 20, val: false},
    { Id: 23, Name: 'LE History / Disclosure Log Entries', Calcs: [Ccalc2, Mcalc2, Hcalc2], Emult: 3, Clicks: 9, Seconds: 300, val: false},
    { Id: 24, Name: 'Loan Folder Automation', Calcs: [Ccalc2, Mcalc2, Hcalc2], Emult: 3, Clicks: 4, Seconds: 24, val: false},
    { Id: 25, Name: 'MERS Auto Registration', Calcs: [Ccalc4, Mcalc4, Hcalc4], Emult: 1, Clicks: 9, Seconds: 228, val: false},
    { Id: 26, Name: 'Navigation Buttons', Calcs: [Ccalc2, Mcalc2, Hcalc2], Emult: 15, Clicks: 4, Seconds: 7.53, val: false},
    { Id: 27, Name: 'Purchase Advice Automation', Calcs: [Ccalc4, Mcalc4, Hcalc4], Emult: 1, Clicks: 66, Seconds: 340, val: false},
    { Id: 28, Name: 'Start Servicing', Calcs: [Ccalc4, Mcalc4, Hcalc4], Emult: 1, Clicks: 7, Seconds: 104, val: false},
    { Id: 29, Name: 'Infinity Solutions', Calcs: [Ccalc2, Mcalc2, Hcalc2], Emult: 7, Clicks: 75, Seconds: 150, val: false},
    { Id: 30, Name: 'Round Robin', Calcs: [Ccalc3, Mcalc3, Hcalc3], Emult: 2, Clicks: 13, Seconds: 225, val: false},


    { Id: 31, Name: 'Appraisal Assistant', Calcs: [Ccalc3, Mcalc3, Hcalc3], Emult: 1.5, Clicks: 40, Seconds: 150, val: false},
    { Id: 32, Name: 'Escrow Manager', Calcs: [Ccalc2, Mcalc2, Hcalc2], Emult: 1, Clicks: 92, Seconds: 300, val: false},
    { Id: 33, Name: 'QC Regulator', Calcs: [Ccalc4, Mcalc4, Hcalc4], Emult: 2, Clicks: 150, Seconds: 300, val: false},
    { Id: 34, Name: 'Enhanced Condition Manager', Calcs: [Ccalc4, Mcalc4, Hcalc4], Emult: 20, Clicks: 8, Seconds: 45, val: false},

    
    { Id: 35, Name: 'Dup Check', Calcs: [Ccalc2, Mcalc2, Hcalc2], Emult: 1, Clicks: 16, Seconds: 78, val: false},
    { Id: 36, Name: 'ITP Automation', Calcs: [Ccalc2, Mcalc2, Hcalc2], Emult: 1, Clicks: 10, Seconds: 60, val: false},
    
];