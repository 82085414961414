import React from "react";
import { Form } from "react-bootstrap";
import CurrencyInput from 'react-currency-input-field';

export default function CurrencyTextInput({
    label,
    id,
    name,
    value,
    valueChange,
    onKeyPress
}) {
  return (
    <div className="form-group">
      <Form.Label aria-label={label} className={"noselect"}>
        {label}
      </Form.Label>
      <CurrencyInput
        id={id}
        name={name}
        placeholder="Please enter a number"
        //   defaultValue={0}
        decimalsLimit={0}
        onValueChange={(v, name) => valueChange(v)}
        value={value}
        className="form-control"
        onKeyPress={onKeyPress}
      />
    </div>
  );
}
